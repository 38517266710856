<!-- 业务记录 -- 退费记录-- 列表 -->
<template>
  <div>
    <list-template
        :current-page="page"
        :is-check-input-box="false"
        :loading="loading"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        @onChangePage="handleCurrentChange"
        @onExportData="onExport"
    >
    </list-template>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ListTemplate from "@/components/pages/List.vue"

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {ListTemplate},
  data() {
    return {
      loading: true,
      search: {},
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,
      tableConfig: [
        {prop: "name", label: "词条"},
        {prop: 'creator', label: "创建教师"},
        {prop: "subject_name", label: "学科"},
        {prop: "school_name", label: "校区"},
        {prop: "created_at", label: "创建时间"},
      ],
    }
  },
  created() {
    this.$store.commit("setPage", 1);
    this.getData()
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(["page"])
  },
  methods: {
    getData() {
      this.loading = true
      let params = {page: this.page}
      this.tableData = [];
      this.$_axios2('api/teacher-entry/list', {params}).then(res => {
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => {
        this.loading = false
      })
    },

    handleCurrentChange(val) {
      this.$store.commit("setPage", val);
      this.getData()
    },


    // 导出数据
    onExport() {
      this.$_axios2.get('api/teacher-entry/list-export').then(res => {
        this.$tools.download('教师提交词条', res.data)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.role-list {
  padding-top: 24rem;
}

.button {
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  margin-bottom: 30rem;
  margin-left: 34rem;
  opacity: 1;
  border-radius: 4rem;
}

.table {
  margin-left: 32rem;
}

::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  font-size: 14rem;
  font-weight: normal;
  box-sizing: border-box;
  height: 48rem;
  text-align: left;
  color: #333333;
}

.header {
  font-size: 16rem;
  font-weight: bold;
  line-height: 24rem;
  position: fixed;
  top: 120rem;
  left: 300rem;
  letter-spacing: 3.5rem;
  color: #333333;
}

.el-button--text {
  font-size: 14rem;
  font-weight: normal;
  margin-right: 40rem;
  color: #3491fa;
}
</style>
